import { useContext, useEffect, useState } from "react";
import { storeContext } from "../../../store/root-store";
import { useTelegram } from "../../../hooks/useTelegram";
import { observer } from "mobx-react-lite";
import { EventComp } from "../list/EventComp";
import { MyEventComponent } from "../../../types/MyEventComponent";
import { MyCustomScroll } from "../../common/MyCustomScroll";
import { QueryService } from "../../../services/QueryService";
import { MyModal } from "../../common/MyModal";
import { useNavigate } from "react-router-dom";

const lengthOfMyEventsPage = 3;
const start = 0;

export const MyListOfEvents = observer(() => {
  const { eventsStore, messagesStoreGl } = useContext(storeContext);

  const [isModalShown, setIsModalShown] = useState(false);

  const [modalText, setModalText] = useState("");

  const setModalInfo = (event: MyEventComponent) => {
    setModalText("test");
    setIsModalShown(true);
  };

  const { showTgButton, user } = useTelegram();

  const getListOfUsersEvents = async (currentPage: number) => {
    const res = await eventsStore.getListOfUserEvents(
      currentPage,
      lengthOfMyEventsPage,
    );
    return res;
  };

  useEffect(() => {
    eventsStore.myListOfEvents = [];
  }, []);

  return (
    <div className="mt-2">
      <MyCustomScroll loadData={getListOfUsersEvents}>
        {eventsStore.myListOfEvents.map((el) => {
          return (
            <MyEvent onStatusClick={setModalInfo} key={el.id} event={el} />
          );
        })}
      </MyCustomScroll>
      {isModalShown && (
        <MyModal
          title={"Текущий статус заявки"}
          onClose={() => {
            setIsModalShown(false);
          }}
        >
          <p>{modalText}</p>
        </MyModal>
      )}
    </div>
  );
});

interface IMyEvent {
  event: MyEventComponent;
  onStatusClick: (event: MyEventComponent) => any;
}
const MyEvent = observer(({ event, onStatusClick }: IMyEvent) => {
  const { eventsStore } = useContext(storeContext);
  const nav = useNavigate();

  const deleteEvent = (eventId: number) => {
    const promptRes = window.confirm("Вы уверены?");
    if (promptRes) {
      eventsStore.deleteEvent(eventId);
      eventsStore.deleteFromMyListOfEvents(eventId);
    }
  };

  return (
    <div className="row mt-2">
      <div className="col-sm-7 col-7">
        <EventComp event={event} className={""} />
      </div>
      <div className="col-sm-5 col-5 event-panel">
        <button
          className="btn btn-danger mt-1"
          onClick={(e) => deleteEvent(event.id)}
        >
          Удалить
        </button>
        <button
          className="btn btn-warning mt-1"
          onClick={(e) => {
            nav("/edit/" + event.id);
          }}
        >
          Редактировать
        </button>
        <button
          className="btn btn-primary mt-2 "
          onClick={() => {
            onStatusClick(event);
          }}
        >
          На модерации
        </button>
      </div>
    </div>
  );
});
