import { Link } from "react-router-dom";

export const Header = () => {
  return (
    <div className="d-flex my-header">
      <div>
        <Link to={"/"}>
          <img
            width={70 + "px"}
            height={"auto"}
            src="/Group 30MyLogo.svg"
            alt=""
          />
        </Link>
      </div>
      <div className="my-header-city">Краснодар</div>
    </div>
  );
};
