import { makeAutoObservable } from "mobx";
export default interface ITagWithRelated{
    id: number,
    name: string,
    related: {
        id: number,
        distance:number
    }[]
}
export class Tags {
  public listOfTags: ITagWithRelated[] = [];
  constructor() {
    makeAutoObservable(this);
  }
}
export const tagsStore = new Tags()
