import React, { useContext } from "react";
import { QueryService } from "../../../services/QueryService";
import { EventPreview } from "../../../types/EventPreview";
import { AdminService } from "../../../services/AdminService";
import { observe } from "mobx";
import { observer } from "mobx-react-lite";
import { storeContext } from "../../../store/root-store";

interface IEvent {
  key?: any;
  event: EventPreview;
  className: string;
}
const getImgPath = (imgPaths: string) => {
  const paths = imgPaths.split(";");
  return paths[0];
};
export const EventComp = observer(({ event, className }: IEvent) => {
  {
    const { tagsGl } = useContext(storeContext);
    const getDistance = (eventId: number) => {
      let distance = 0;
      tagsGl.listOfTags.forEach(el => {
        el.related.forEach(event => {
          if (event.id === eventId) {
            distance = event.distance
          }
        })
      })
      return distance.toFixed(4);
    }

    return (
      <div className={className}>
        <div className="card">
          <div style={{ width: 100 + "%", height: 25 + "vh" }}>
            {event.img_path && (
              <img
                width={100 + "%"}
                height={100 + "%"}
                src={
                  QueryService.getServerUri() + "/" + getImgPath(event.img_path)
                }
                className="card-img-top"
                alt="..."
              />
            )}
            {!event.img_path && (
              <img
                width={100 + "%"}
                height={100 + "%"}
                src={"/no-img.svg"}
                className="card-img-top"
                alt="..."
              />
            )}
          </div>
          <div className="card-body">
            <p
              className=""
              style={{
                height: 25 + "vh",
                overflow: "hidden",
                wordBreak: "break-all",
              }}
            >
              {event.body}
            </p>
            {AdminService.getService().isAdmin() && <span>
              {getDistance(event.id) + ""}
            </span>}
          </div>
        </div>
      </div>
    );
  }
});
