export class DateHelper {
  /**
   * Получить начало и конец недели в секундах
   */
  public static getWeekPeriodSec() {
    const today = new Date();
    const dayOfWeek = today.getDay(); // Получаем день недели (0 - воскресенье, 1 - понедельник, ..., 6 - суббота)

    // Вычисляем дату начала недели (понедельник)
    const startOfWeek = new Date(today);
    startOfWeek.setDate(
      today.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1),
    );

    // Вычисляем дату конца недели (воскресенье)
    const endOfWeek = new Date(today);
    endOfWeek.setDate(today.getDate() + (7 - dayOfWeek));

    // Функция для форматирования даты в dd.mm.yyyy
    const formatDate = (date: Date) => {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Месяцы начинаются с 0
      const year = date.getFullYear();
      return `${year}.${month}.${day}`;
    };

    return {
      dateFromSec: Math.floor(startOfWeek.getTime() / 1000),
      dateToSec: Math.floor(endOfWeek.getTime() / 1000),
    };
  }
}
