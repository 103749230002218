import { makeAutoObservable } from "mobx";
import { QueryService } from "../services/QueryService";
import { IFullEvent } from "../interfaces/IFullEvent";
import { ImgGalleryItem } from "../interfaces/ImgGalleryItem";

export class SingleEvent {
  public fullEvent: IFullEvent = {} as IFullEvent;
  constructor() {
    makeAutoObservable(this);
  }

  /**
   * Получить полную информацию по конкретному событию
   * @param eventId
   */
  public async loadSingleEvent(eventId: number) {
    const res = await QueryService.getQuery("/api/events/getById/" + eventId);
    console.log("single create");
    console.log(res);
    this.fullEvent = res?.data.data.result[0];
  }

  /**
   * Получить изображениея для галереи
   * @param imgPath
   */
  public getImgForGallery(imgPath: string): ImgGalleryItem[] {
    const paths = imgPath.split(";");
    return paths.map((el) => {
      if (!el) {
        return {
          original: "/no-img.svg",
          thumbnail: "/no-img.svg",
        };
      }
      return {
        original: QueryService.getServerUri() + "/" + el,
        thumbnail: QueryService.getServerUri() + "/" + el,
      };
    });
  }
}
export const singleEvent = new SingleEvent();
