import { useContext, useEffect, useState } from "react";
import { storeContext } from "../../../store/root-store";
import { observer } from "mobx-react-lite";
import { QueryService } from "../../../services/QueryService";
import { ITag } from "../../../interfaces/ITag";
import ITagWithRelated from "../../../store/Tags";

export const TagsPanel = observer(() => {
  const [listOfTags, setListOfTags]: [ITag[], Function] = useState([]);
  const { eventsStore, messagesStoreGl, settingsGl,tagsGl } = useContext(storeContext);

  const getListOfTags = async () => {
    const data = await QueryService.getQuery("/api/tags/getTags");
    // console.log(data?.data.data as []);
    setListOfTags(data?.data.data);

    // setListOfTags([(data as any).data]);
  };

  useEffect(() => {
    getListOfTags();
  }, []);
  const onTagClick = (t: ITagWithRelated) => {
    settingsGl.selectedListOfEvents = t.related
    settingsGl.selectedTag = t;
    eventsStore.listOfPreviewEvents = []
  };

  return (
    <div className="list-of-tags mt-2 p-2">
      {tagsGl.listOfTags.map((tag) => {
        return (
          <div
            className={
              tag.id === settingsGl.selectedTag.id
                ? "single-tag my-time-panel-button my-time-panel-button-active "
                : "single-tag my-time-panel-button "
            }
            onClick={() => onTagClick(tag)}
          >
            {tag.name + '' + "(" + tag.related.length + ")"}
          </div>
        );
      })}
    </div>
  );
});
