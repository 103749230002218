import { useContext, useState } from "react";
import { storeContext } from "../../../../store/root-store";
import { useTelegram } from "../../../../hooks/useTelegram";
import { MyTabMenu } from "./MyTabMenu";
import { TabsEnum } from "../../../../enums/TabsEnum";
import { FirstTabPreviewInfo } from "./FirstTabPreviewInfo";
import { SecondTabFullInfo } from "./SecondTabFullInfo";
import { IFormEventType } from "../../../../types/FormEventType";
import { FilePicker } from "../FilePicker";
import { getFormatedDate } from "../../../../utils";

export interface IEditEventTabsIndex {
  formSendUri: string;
  showFilePicker: boolean;
  formData: IFormEventType;
}
export const EditEventTabsIndex = ({
  formSendUri,
  showFilePicker,
  formData,
}: IEditEventTabsIndex) => {
  const [event, setEvent] = useState(formData);
  const { eventsStore, messagesStoreGl } = useContext(storeContext);
  const { showTgButton, user } = useTelegram();
  const [selectedFile, setSelectedFile] = useState("");

  const [tabIndex, setTabIndex] = useState(0);

  const changeTabIndex = (index: number) => {
    setTabIndex(index);
  };

  const onSave = async (event: IFormEventType) => {
    const formData = new FormData();
    if (showFilePicker) {
      formData.append("main_img", event["main_img"]);
    } else {
      formData.append("id", "" + event?.id);
    }
    formData.append("user_id", user?.id ? user.id + "" : "709420289");
    console.log("!!!!!");
    console.log(event);
    formData.append(
      "description",
      event?.description ? event.description + "" : "",
    );
    formData.append(
      "event_date",
      event?.event_date ? getFormatedDate(event.event_date) : "",
    );

    formData.append("price", event?.price ? event.price + "" : "");
    // formData.append("title", event?.title ? event.title + "" : "");
    formData.append("location", event?.location ? event.location + "" : "");

    //изменить
    // formData.append("city_id", create.event_date);

    formData.append("city_id", "12");
    await eventsStore.saveEventApi(formData, formSendUri);
  };

  return (
    <div>
      <MyTabMenu changeTab={changeTabIndex} selectedTab={tabIndex} />
      <div className="my-event-form">
        {tabIndex == TabsEnum.firstTab && showFilePicker && (
          <FilePicker setEvent={setEvent} />
        )}
        {tabIndex == TabsEnum.firstTab && (
          <FirstTabPreviewInfo event={event} setEvent={setEvent} />
        )}
        {tabIndex == TabsEnum.secondTab && (
          <SecondTabFullInfo event={event} setEvent={setEvent} />
        )}
      </div>
      <div className="d-flex justify-content-end mt-2">
        <button
          onClick={(e) => onSave(event)}
          type="submit"
          className="btn my-event-button"
        >
          Сохранить
        </button>
      </div>
    </div>
  );
};
