import { BasePage } from "../../common/BasePage";
import { observer } from "mobx-react-lite";
import { useContext, useEffect } from "react";
import { ListOfEventsForCheck } from "./ListOfEventsForCheck";
import { storeContext } from "../../../store/root-store";
import { ICheckEventStore } from "../../../store/CheckEvents";

export const CheckPage = observer(() => {
  const { checkEventsGl } = useContext(storeContext);

  useEffect(() => {
    checkEventsGl.loadEvents();
  }, []);
  return (
    <BasePage>
      <div>
        <ListOfEventsForCheck listOfEventsForCheck={checkEventsGl.data} />
      </div>
    </BasePage>
  );
});
