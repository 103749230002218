import { observer } from "mobx-react-lite";
import { CheckEvent, CheckEvents } from "../../../store/CheckEvents";
import { useContext, useState } from "react";
import { storeContext } from "../../../store/root-store";
import { useTelegram } from "../../../hooks/useTelegram";
import { currentDevUser } from "../../../env";
import { QuestionsCard } from "./QuestionsCard";

export const ListOfEventsForCheck = observer(
  ({ listOfEventsForCheck }: { listOfEventsForCheck: CheckEvent[] }) => {
    return (
      <div>
        {listOfEventsForCheck.map((el) => {
          return <CheckEventComp key={el.id} ev={el} />;
        })}
      </div>
    );
  },
);
export const CheckEventComp = observer(({ ev }: { ev: CheckEvent }) => {
  const [isEvent, setIsEvent] = useState(false);
  const [eventDate, setEventDate] = useState("");
  const { checkEventsGl } = useContext(storeContext);
  const { user } = useTelegram();

  const onAnswer = async (eventInfo: {
    is_event_real: boolean;
    event_date_real?: string;
  }) => {
    await checkEventsGl.updateUnprocessedEvent({
      ...eventInfo,
      id: ev.id,
      checker: user?.id ?? currentDevUser,
    });
    await checkEventsGl.loadEvents();
  };

  return (
    <>
      {isEvent == false && (
        <div className="card" key={ev.id}>
          {isEvent == false && (
            <div>
              <div className="card-header">
                <div>Это приглашение на будущее событие?</div>
                <div>
                  <span className="">Дата публикации :{ev.create_date}</span>
                </div>
              </div>
              <div
                className="card-body"
                style={{ maxHeight: 50 + "vh", overflowY: "scroll" }}
              >
                {ev.text}
              </div>
            </div>
          )}

          {/*<hr />*/}
          <div className="card-footer m-2">
            <div className="">
              <div
                className="btn btn-success me-2 mb-2"
                onClick={(e) => {
                  setIsEvent(true);
                }}
              >
                Да
              </div>
              <div
                className="btn btn-danger me-2 mb-2"
                onClick={(e) => {
                  setIsEvent(false);
                  onAnswer({
                    is_event_real: false,
                  });
                }}
              >
                Нет
              </div>
            </div>
          </div>
        </div>
      )}
      {isEvent === true && (
        <QuestionsCard
          ev={ev}
          onAnswer={onAnswer}
          setEventDate={setEventDate}
          setIsEvent={setIsEvent}
        />
      )}
    </>
  );
});
