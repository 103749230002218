import { ListOfEvents } from "./ListOfEvents";
import { BasePage } from "../../common/BasePage";
import { DatePanel } from "./DatePanel";
import { useTelegram } from "../../../hooks/useTelegram";
import { Telegram } from "@tg-app/types";
import { TagsPanel } from "./TagsPanel";

// @ts-ignore
// const tg: Telegram.WebApp = window.Telegram.webApp;

export const ListPage = () => {
  const { user, tg, onToggleButton } = useTelegram();
  return (
    <BasePage>
      <DatePanel />
      <TagsPanel />
      <ListOfEvents />
    </BasePage>
  );
};
