import { CheckEvent } from "../../../store/CheckEvents";

export const QuestionsCard = ({
  ev,
  onAnswer,
  setEventDate,
  setIsEvent,
}: {
  ev: CheckEvent;
  onAnswer: Function;
  setEventDate: Function;
  setIsEvent: Function;
}) => {
  return (
    <div className="card">
      <div className="card-header">
        <div>Уточняющие вопросы</div>
      </div>
      <div
        className="card-body"
        style={{ maxHeight: 50 + "vh", overflowY: "scroll" }}
      >
        <div className="">
          <label htmlFor="" className="form-label">
            Выбери дату события
          </label>
          <input
            type="date"
            onChange={(e) => {
              setEventDate(e.target.value);
              onAnswer({
                is_event_real: true,
                event_date_real: e.target.value,
              });
            }}
            className="form-control"
          />
        </div>
        <div className="mt-2">
          <button
            className="btn btn-warning"
            onClick={(e) => {
              onAnswer({
                is_event_real: true,
              });
            }}
          >
            Нет даты
          </button>
        </div>
      </div>
      <div className="card-footer">
        <button
          className="btn btn-success"
          onClick={() => {
            setIsEvent(false);
          }}
        >
          Обратно к тексту
        </button>
      </div>
    </div>
  );
};
