import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect } from "react";
import { storeContext } from "../../../store/root-store";
import { EventComp } from "./EventComp";
import { MyCustomScroll } from "../../common/MyCustomScroll";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { DateButtons } from "../../../enums/DateButtons";
import { Settings } from "../../../store/Settings";
const EventMemo = React.memo(EventComp);
export const ListOfEvents = observer(() => {
  const { eventsStore, settingsGl } = useContext(storeContext);
  const nav = useNavigate();

  const getEvents = (currentDayFilter: DateButtons, chunkLength: number) => {
    const { startSec, finishSec } = Settings.getTimeInterval(currentDayFilter);
    const selectedEvents = settingsGl.selectedListOfEvents;
    return async (currentPage: number = 0) => {
      return await eventsStore.getListOfPreviewEvents(
        startSec,
        finishSec,
        selectedEvents,
        currentPage,
        chunkLength,
      );
      // return res;
    };
  };
  const onClick = (id: number) => {
    nav("events/" + id);
  };

  useEffect(() => {
    eventsStore.listOfPreviewEvents = [];
  }, []);

  return (
    <div className="">
      {/*<MyScrollComponent2 />*/}
      <MyCustomScroll
        key={settingsGl.currentDayFilter + settingsGl.selectedTag.id}
        loadData={getEvents(
          settingsGl.currentDayFilter,
          settingsGl.chunkLength,
        )}
      >
        <div className="row" style={{ marginTop: 1 + "rem" }}>
          {eventsStore.listOfPreviewEvents
            // .filter((el) => el.img_path)
            .map((el) => {
              return (
                <div
                  key={el.id}
                  className="col-6 col-sm-6 mt-2"
                  onClick={(e) => {
                    onClick(el.id);
                  }}
                >
                  <EventComp
                    key={el.id}
                    event={el}
                    // className="col-6 col-sm-6 mt-2"
                    className=""
                  />
                </div>
              );
            })}
        </div>
      </MyCustomScroll>
    </div>
  );
});
