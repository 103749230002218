import { currentDevUser, isDev } from "../env";
import { tg, useTelegram } from "../hooks/useTelegram";

export class AdminService {
  private static current: AdminService;

  public listOfAdmins: number[] = [709420289, 1254124342];

  constructor() {
    if (isDev) {
      this.listOfAdmins.push(currentDevUser);
    }
  }

  /**
   * Является ли текущий пользователь админом?
   */
  public isAdmin() {
    const user = tg?.initDataUnsafe?.user;
    let idToSearch = user?.id ?? 0;
    if (isDev) {
      idToSearch = currentDevUser;
    }
    return this.listOfAdmins.includes(idToSearch);
  }

  /**
   * Получить экземляр класса AdminService
   */
  public static getService() {
    if (!this.current) {
      this.current = new AdminService();
    }
    return this.current;
  }
}
